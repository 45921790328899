import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import Grid from '../components/grid'

import styled from "styled-components"
import tw from "tailwind.macro"


const Section = styled.section`
  ${tw`max-w-md mx-auto`};
`

const List = styled.section`
  ${tw`max-w-md flex flex-col`};
`

const StyledLink = styled(Link)`${tw`text-xl mb-2 no-underline text-red hover:no-underline`}`

class TagsIndex extends React.Component {
    render() {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')
        const tags = get(this, 'props.data.allContentfulBlogPost.group')

        return (
            <Layout location={this.props.location} >
                <Helmet title={`All Tags | ${siteTitle}`} />
                <h1 className="mt-12 mb-6"># Articles by Tag</h1>
                <List>
                    {tags.map(({ fieldValue, totalCount }) => {
                        return (
                            <StyledLink to={`tags/${fieldValue}`}>{fieldValue} ({totalCount})</StyledLink>
                        )
                    })}
                </List>
            </Layout>
        )
    }
}

export default TagsIndex

export const pageQuery = graphql`
  query TagsIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(limit: 2000) {
        group(field: tags) {
            fieldValue
            totalCount
        }
    }
  }
`
